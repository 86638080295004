import React, {Component, useEffect, useState, useRef} from 'react';
import { render } from "react-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  TwitterShareButton,
  TwitterIcon
} from 'react-share';
import './App.css';
import { lightGreen } from '@material-ui/core/colors';

class App extends React.Component{
  input = '';

  haikuCreate(){
      var haiku = "┏━━━┓\n" +
                  "\n" +
                  "\n" +
                  "\n" +
                  "\n" +
                  "\n" +
                  "\n" +
                  "\n" +
                  "\n" +
                  "\n" +
                  "\n" +
                  "\n" +
                  "\n" +
                  "\n" +
                  "\n"  +
                  "┗━━━┛\n" 
      return haiku;
  }

  constructor(props){
    super(props);
    this.state = {
      text1:"",
      text2:"",
      text3:"",
      outputtext:this.haikuCreate(),
      url:"http://haiku.riroi.com",
      tag:"#俳句めーかー"
    };
    this.toFullWidth = this.toFullWidth.bind(this);
    this.doCopy = this.doCopy.bind(this);
  }

  divStyle = {
    textAlign:"center",
    margin:"0 auto",
  }

  headStyle = {
    display:"block",
    backgroundColor:"lightgreen",
    color:"white",
    textAlign:"center",
  }

  inputStyle = {
    fontSize:"10pt",
    margin:"10px"
  }

  outputStyle = {
    fontSize:"10pt",
    margin:"10px",
  }

  btnStyle = {
    fontSize:"20pt",
    padding:"0px 10px"
  }

  doCopy() {
    var copyTextArea = document.getElementsByName("output")[0];
    copyTextArea.select();
    document.execCommand("copy")
  };

  toFullWidth(input) {
    var str = input.replace(/[ー]/g,'｜');
    return str.replace(/[A-Za-z0-9]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) + 0xFEE0)})
  };

  render (){
    return(
      <div style={this.divStyle}>
      <header style={this.headStyle} >
        <h1 >俳句めーかー</h1>
      </header>
      <br />
      <text >五</text>
      <br />
      <TextField type="text" name="first" variant="outlined" variant="outlined" style={this.inputStyle} value={this.state.text1} onChange={e => this.handleOnchange1(e)}/>
      <br />
      <text >七</text>
      <br />
      <TextField type="text" name="second" style={this.inputStyle} variant="outlined" value={this.state.text2} onChange={e => this.handleOnchange2(e)}/>
      <br />
      <text >五</text>
      <br />
      <TextField type="text" name="third" style={this.inputStyle} variant="outlined" value={this.state.text3} onChange={e => this.handleOnchange3(e)}/>
      <br />
 
      <textarea cols="25" rows="20" name="output" style={this.outputStyle} value={this.state.outputtext} OnChange={e => this.handleOnchange4(e)}/>
      <br />
      <div align="center">
          <Button variant="contained" color="primary" size="large" onClick={e => this.doCopy()}>コピーする</Button>
          <br />
        </div>
        <div align="center">
          <TwitterShareButton title={this.state.outputtext+this.state.tag} url={this.state.url}>
          <TwitterIcon size={48} round />
          </TwitterShareButton>
        </div>
        <br />
      </div>
    )
  }
  handleOnchange1(e
    ){
    var text1data = e.target.value
    text1data =  " " + text1data 
    text1data = this.toFullWidth(text1data)
    var text2data = this.state.text2
    text2data =  "   " + text2data 
    text2data = this.toFullWidth(text2data)
    var text3data = this.state.text3
    text3data =  "     " + text3data 
    text3data = this.toFullWidth(text3data)
    var output1bara = this.state.outputtext.split("\n")
    for (let i =0; i<12; i++){
      if(text1data.length>i){
        text1data =  text1data + " "
      }
      if(text2data.length>i){
        text2data =  text2data + " "
      }
      if(text3data.length>i){
        text3data =  text3data + " "
      }
        output1bara[i+2] = " " + text3data[i] + text2data[i] + text1data[i]
    }
    this.setState(
      {outputtext:output1bara.join("\n")});
    this.setState({text1: e.target.value}); 
  }

  handleOnchange2(e){
    var text1data = this.state.text1
    text1data =  " " + text1data 
    text1data = this.toFullWidth(text1data)
    var text2data = e.target.value
    text2data =  "   " + text2data
    text2data = this.toFullWidth(text2data) 
    var text3data = this.state.text3
    text3data =  "     " + text3data
    text3data = this.toFullWidth(text3data) 
    var output1bara = this.state.outputtext.split("\n")
    for (let i =0; i<12; i++){
      if(text1data.length>i){
        text1data =  text1data + " "
      }
      if(text2data.length>i){
        text2data =  text2data + " "
      }
      if(text3data.length>i){
        text3data =  text3data + " "
      }
        output1bara[i+2] = " " + text3data[i] + text2data[i] + text1data[i]
    }
    this.setState(
      {
        outputtext:output1bara.join("\n")});
        this.setState({text2: e.target.value}); 
  }

  handleOnchange3(e){
    var text1data = this.state.text1
    text1data =  " " + text1data 
    text1data = this.toFullWidth(text1data)
    var text2data = this.state.text2
    text2data =  "   " + text2data
    text2data = this.toFullWidth(text2data) 
    var text3data = e.target.value
    text3data = "     " + text3data
    text3data = this.toFullWidth(text3data)
     
    var output1bara = this.state.outputtext.split("\n")
    for (let i =0; i<12; i++){
      if(text1data.length>i){
        text1data =  text1data + " "
      }
      if(text2data.length>i){
        text2data =  text2data + " "
      }
      if(text3data.length>i){
        text3data =  text3data + " "
      }
        output1bara[i+2] = " " + text3data[i] + text2data[i] + text1data[i]
    }
    this.setState(
      {outputtext:output1bara.join("\n")});
      this.setState({text3: e.target.value});
  }
  handleOnchange4(e){ 
    this.setState({outputtext: e.target.value});   
  }

}



export default App;
